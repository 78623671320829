/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 13.5a.5.5 0 01-.5.5h-6a.5.5 0 010-1h4.793L2.146 2.854a.5.5 0 11.708-.708L13 12.293V7.5a.5.5 0 011 0z"/>',
    },
});
